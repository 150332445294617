@font-face {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 400;
  src: url("noto-sans-v27-latin-regular.50f8e1c0.eot");
  src: local(""), url("noto-sans-v27-latin-regular.50f8e1c0.eot#iefix") format("embedded-opentype"), url("noto-sans-v27-latin-regular.aac30b69.woff2") format("woff2"), url("noto-sans-v27-latin-regular.66039195.woff") format("woff"), url("noto-sans-v27-latin-regular.26c9aea2.ttf") format("truetype"), url("noto-sans-v27-latin-regular.128546f9.svg#NotoSans") format("svg");
}

@font-face {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 700;
  src: url("noto-sans-v27-latin-700.5fbd892d.eot");
  src: local(""), url("noto-sans-v27-latin-700.5fbd892d.eot#iefix") format("embedded-opentype"), url("noto-sans-v27-latin-700.2c1da7d7.woff2") format("woff2"), url("noto-sans-v27-latin-700.8511a390.woff") format("woff"), url("noto-sans-v27-latin-700.ecc719c8.ttf") format("truetype"), url("noto-sans-v27-latin-700.ac78ec2c.svg#NotoSans") format("svg");
}

html {
  -webkit-tap-highlight-color: #0000;
  -webkit-font-smoothing: subpixel-antialiased;
  font-smoothing: subpixel-antialiased;
}

.relative {
  position: relative;
}

.resetList {
  margin: 0;
  padding: 0;
  list-style: none;
}

.mainwidth {
  width: 1196px;
  margin: 0 auto;
  padding: 0 10px;
}

.border-radius-bottom {
  -moz-border-radius-bottomright: 12px;
  -moz-border-radius-bottomleft: 12px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.absolute {
  z-index: 2;
  position: absolute;
}

.mask {
  z-index: 20;
  position: relative;
}

.ontop {
  z-index: 10;
  height: 350px;
  background: #fff;
  position: relative;
}

.disable-mobile {
  display: block;
}

.visible-tablet, .visible-mobile {
  display: none;
}

.visible-desktop {
  display: normal;
}

.clear {
  clear: both;
}

body {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  background: #fff;
  font-family: Noto Sans, sans-serif;
  font-size: 1em;
  line-height: 1.5em;
}

h1 {
  margin: 0;
  font-size: 4em;
  line-height: 1em;
}

h2 {
  margin: 0;
  font-size: 1.8em;
  font-style: italic;
  line-height: 1.5em;
}

h3 {
  margin: 0 0 3px;
  font-size: 1.2em;
  font-style: italic;
  line-height: 1em;
}

h3.kontakth3 {
  margin-top: 10px;
}

.uldecimal {
  list-style: decimal;
}

.ulalpha {
  list-style: lower-alpha;
}

ul {
  margin: 0 0 20px 15px;
}

ul li {
  line-height: 1.5em;
}

dl {
  clear: both;
  margin: 0;
  padding: 0;
  list-style: none;
}

dt {
  margin: 20px 0 0;
  font-size: 1.2em;
  font-style: italic;
  font-weight: 700;
  line-height: 1.5em;
}

dd {
  background: url("list.1a911070.png") 0 10px no-repeat;
  margin: 0;
  padding: 0 0 0 10px;
  line-height: 1.5em;
  list-style: disc;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

div.cols div {
  float: left;
}

div.cols div.last {
  float: right;
  margin: 0 0 0 -20px;
}

.row-2 {
  width: 152px;
}

.row-3 {
  width: 228px;
}

.row-4 {
  width: 304px;
}

.row-5 {
  width: 380px;
}

.row-6 {
  width: 456px;
}

.row-7 {
  width: 532px;
}

.row-8 {
  width: 608px;
}

.row-9 {
  width: 684px;
}

.row-10 {
  width: 760px;
}

.row-11 {
  width: 836px;
}

.row-12 {
  width: 912px;
}

.row-13 {
  width: 988px;
}

.row-14 {
  width: 1064px;
}

div.mainwidth {
  width: 1196px;
  margin: 0 auto;
  padding: 0 10px;
}

header {
  height: 116px;
  width: 100%;
  z-index: 2000;
  background: url("headerback.65c3651b.png") 0 0 repeat-x;
  position: fixed;
  top: 0;
}

section {
  min-height: 200px;
  z-index: 20;
  position: relative;
}

section#home {
  color: #54758c;
  z-index: 10;
  background: #fff url("borderbottom.4900b659.png") 0 100% repeat-x;
  margin: 116px 0 0;
  padding: 0;
}

section#home div.mainwidth {
  padding-bottom: 8px !important;
}

section#home div.fair {
  height: 376px;
  width: 100%;
  z-index: 1;
  background: #fafbf1;
  position: absolute;
  top: 0;
}

section#home p.abstand {
  margin-top: 120px;
}

section#space {
  height: 700px;
  z-index: 1;
  background: #fafbf1;
  margin: 0;
  padding: 0;
}

section#angebot, section#netzwerk {
  color: #fff;
  background: #54758c;
  margin: 116px 0;
  padding: 56px 0 70px;
}

section#angebot {
  background: #54758c url("border.c2bfd3d0.png") 0 0 repeat-x;
  margin-top: 0;
}

section#profil, section#referenzen, section#kontakt {
  color: #54758c;
  background: #fff;
  margin: 116px 0;
  padding: 56px 0 70px;
}

section#referenzen {
  padding: 0 0 70px;
}

section#impressum {
  color: #fff;
  background: #575759;
  margin: 116px 0 0;
  padding: 56px 0 0;
}

section#privacypolicy {
  color: #54758c;
  background: #fff;
  margin: 116px 0 0;
  padding: 56px 0 0;
}

section#privacypolicy p {
  padding-left: 2rem;
}

section#privacypolicy a, section#home a, section#profil a, section#referenzen a, section#kontakt a {
  color: #54758c;
}

section#impressum a, section#angebot a, section#netzwerk a {
  color: #fff;
}

section#angebot p, section#impressum p {
  margin: 0 0 1.8em;
}

nav ul {
  -moz-border-radius-bottomright: 12px;
  -moz-border-radius-bottomleft: 12px;
  width: 603px;
  height: 40px;
  float: left;
  background: #575759;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  margin: 0;
  padding: 20px 0 0;
  list-style: none;
}

nav ul li {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline;
}

nav ul li a:link, nav ul li a:visited {
  color: #fff;
  text-transform: uppercase;
  padding: 0 0 0 25px;
  font-size: 14px;
  text-decoration: none;
}

nav ul li a.current, nav ul li a:hover, nav ul li a:active {
  background: url("navact.a9ac7e71.png") 10px 5px no-repeat;
}

div#logo {
  float: right;
}

div#logo a.logo {
  height: 114px;
  width: 359px;
  white-space: nowrap;
  text-indent: 999em;
  background: url("logo.9ce79557.png") 0 0 no-repeat;
  display: block;
  overflow: hidden;
}

#referenzen div.icon {
  height: 32px;
  width: 32px;
  background: url("sprite.ddeac799.png") no-repeat;
  margin: 20px 0 5px;
  padding: 0 !important;
}

#referenzen div.sprite-It {
  width: 32px;
  height: 32px;
  background-position: 0 0;
}

#referenzen div.sprite-automotive {
  width: 32px;
  height: 32px;
  background-position: 0 -42px;
}

#referenzen div.sprite-bauwesen {
  width: 32px;
  height: 32px;
  background-position: 0 -84px;
}

#referenzen div.sprite-chemie {
  width: 32px;
  height: 32px;
  background-position: 0 -126px;
}

#referenzen div.sprite-dienstleistung {
  width: 32px;
  height: 32px;
  background-position: 0 -168px;
}

#referenzen div.sprite-gesundheit {
  width: 32px;
  height: 32px;
  background-position: 0 -210px;
}

#referenzen div.sprite-handel {
  width: 32px;
  height: 32px;
  background-position: 0 -252px;
}

#referenzen div.sprite-kunst {
  width: 32px;
  height: 32px;
  background-position: 0 -294px;
}

#referenzen div.sprite-lebensmittel {
  width: 32px;
  height: 32px;
  background-position: 0 -336px;
}

#referenzen div.sprite-maschinenbau {
  width: 32px;
  height: 32px;
  background-position: 0 -378px;
}

#referenzen div.sprite-moebel {
  width: 32px;
  height: 32px;
  background-position: 0 -420px;
}

#referenzen div.sprite-soziales {
  width: 32px;
  height: 32px;
  background-position: 0 -462px;
}

div.cols form div {
  float: none;
  clear: both;
}

#contact-form input[type="text"], #contact-form input[type="email"], #contact-form input[type="tel"], #contact-form input[type="url"], #contact-form textarea, #contact-form button[type="submit"] {
  font: 400 12px / 12px Helvetica Neue, Helvetica, Arial, sans-serif;
}

#contact-form {
  text-shadow: 0 1px #fff;
  border-radius: 4px;
}

#contact-form label span {
  cursor: pointer;
  margin: 5px 0;
  font-weight: 900;
  display: block;
}

#contact-form input[type="text"], #contact-form input[type="email"], #contact-form input[type="tel"], #contact-form input[type="url"], #contact-form textarea {
  width: 80%;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 0 0 5px;
  padding: 10px;
  box-shadow: inset 0 1px 2px #ddd, 0 1px #fff;
}

#contact-form input[type="text"]:hover, #contact-form input[type="email"]:hover, #contact-form input[type="tel"]:hover, #contact-form input[type="url"]:hover, #contact-form textarea:hover {
  border: 1px solid #aaa;
  transition: border-color .3s ease-in-out;
}

#contact-form textarea {
  height: 100px;
  max-width: 80%;
}

#contact-form button[type="submit"] {
  cursor: pointer;
  width: 83%;
  color: #fff;
  background: #54758c;
  border: none;
  border-radius: 5px;
  margin: 0 0 5px;
  padding: 10px;
}

#contact-form button[type="submit"]:active {
  box-shadow: inset 0 1px 3px #00000080;
}

#contact-form input:focus, #contact-form textarea:focus {
  border: 1px solid #999;
  outline: 0;
}

::-webkit-input-placeholder {
  color: #888;
}

:-moz-placeholder {
  color: #888;
}

::-moz-placeholder {
  color: #888;
}

:-ms-input-placeholder {
  color: #888;
}

p.error, p.sucsess {
  color: red;
}

#data-privacy {
  z-index: 99999;
  position: fixed;
  bottom: 0;
  right: 0;
}

#data-privacy .data-privacy-btn {
  max-width: 100%;
  cursor: pointer;
  color: #fff;
  background: #54758c;
  border: 1px solid #54758c;
  margin: 0;
  padding: 4px 12px;
  font-family: inherit;
  font-size: 14px;
  text-decoration: none;
  transition: all .2s;
  display: block;
  box-shadow: 0 0 1px #fff;
}

#data-privacy .data-privacy-btn:hover, #data-privacy .data-privacy-btn:focus {
  color: #54758c;
  box-shadow: none;
  background: #fff;
}

/*# sourceMappingURL=index.9b65a964.css.map */
