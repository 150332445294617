/* noto-sans-regular - latin */
@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/noto-sans-v27-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/noto-sans-v27-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/noto-sans-v27-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("../fonts/noto-sans-v27-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/noto-sans-v27-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/noto-sans-v27-latin-regular.svg#NotoSans") format("svg"); /* Legacy iOS */
}
/* noto-sans-700 - latin */
@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/noto-sans-v27-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/noto-sans-v27-latin-700.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/noto-sans-v27-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/noto-sans-v27-latin-700.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/noto-sans-v27-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/noto-sans-v27-latin-700.svg#NotoSans") format("svg"); /* Legacy iOS */
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: subpixel-antialiased;
  font-smoothing: subpixel-antialiased;
}

/*####################*/
/*General Colors and Variables*/
/*####################*/
@black: #000;
@grey: #575759;
@white: #fff;
@blue: #54758c;
@fair: #fafbf1;

@fullwidth: 1196px;

@colwidth: 56px;
@gutterwidth: 20px;
@boxpaddingbottom: 70px;
@boxpaddingtop: 56px;
@boxmargin: 116px;

@basefontSize: 1em;
@largeFont: @basefontSize * 4;
@mediumFont: @basefontSize * 1.8;
@baselineHeight: 1.5em;

/*####################*/
/*define the grid*/
/*####################*/
@wide1:@colwidth + @gutterwidth;
@wide2: (@colwidth + @gutterwidth) * 2;
@wide3: (@colwidth + @gutterwidth) * 3;
@wide4: (@colwidth + @gutterwidth) * 4;
@wide5: (@colwidth + @gutterwidth) * 5;
@wide6: (@colwidth + @gutterwidth) * 6;
@wide7: (@colwidth + @gutterwidth) * 7;
@wide8: (@colwidth + @gutterwidth) * 8;
@wide9: (@colwidth + @gutterwidth) * 9;
@wide10: (@colwidth + @gutterwidth) * 10;
@wide11: (@colwidth + @gutterwidth) * 11;
@wide12: (@colwidth + @gutterwidth) * 12;
@wide13: (@colwidth + @gutterwidth) * 13;
@wide14: (@colwidth + @gutterwidth) * 14;

/*####################*/
/*Mixins*/
/*####################*/
.relative {
  position: relative;
}
.resetList {
  margin: 0;
  padding: 0;
  list-style: none;
}
.mainwidth {
  width: @fullwidth;
  padding: 0 10px 0 10px;
  margin: 0 auto;
}
.border-radius-bottom {
  -webkit-border-bottom-right-radius: 12px;
  -webkit-border-bottom-left-radius: 12px;
  -moz-border-radius-bottomright: 12px;
  -moz-border-radius-bottomleft: 12px;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.box-shadow (@x: 0, @y: 0, @blur: 1px, @color: #999) {
  box-shadow: @arguments;
  -moz-box-shadow: @arguments;
  -webkit-box-shadow: @arguments;
}

/*####################*/
/*Scroll stuff*/
/*####################*/
.absolute {
  position: absolute;
  z-index: 2;
}
.mask {
  position: relative;
  z-index: 20;
}
.ontop {
  position: relative;
  z-index: 10;
  background: @white;
  height: 350px;
}

/*####################*/
/*Show and Hidem and Helper*/
/*####################*/
.disable-mobile {
  display: block;
}
.visible-tablet,
.visible-mobile {
  display: none;
}
.visible-desktop {
  display: normal;
}
.clear {
  clear: both;
}

/*####################*/
/*General Formats*/
/*####################*/
body {
  font-family: "Noto Sans", sans-serif;
  background: @white;
  font-size: @basefontSize;
  line-height: @baselineHeight;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
h1 {
  font-size: @largeFont;
  margin: 0;
  line-height: @baselineHeight - 0.5;
}
h2 {
  font-size: @mediumFont;
  margin: 0;
  font-style: italic;
  line-height: @baselineHeight;
}
h3 {
  font-size: @basefontSize * 1.2;
  line-height: @baselineHeight - 0.5;
  margin: 0 0 3px 0;
  font-style: italic;
}
h3.kontakth3 {
  margin-top: 10px;
}
ul,
li {
  //.resetList;
  //list-style:decimal;
}

.uldecimal {
  list-style: decimal;
  list-style-type: decimal;
}

.ulalpha {
  list-style: lower-alpha;
  list-style-type: lower-alpha;
}

ul {
  margin: 0 0 20px 15px;
}
ul li {
  line-height: @baselineHeight;
}

dl {
  .resetList;
  clear: both;
}

dt {
  font-size: @basefontSize * 1.2;
  line-height: @baselineHeight;
  margin: 20px 0 0 0;
  font-style: italic;
  font-weight: 700;
}
dd {
  list-style: disc;
  margin: 0;
  padding: 0 0 0 10px;
  line-height: @baselineHeight;
  background: url(../../img/list.png) no-repeat 0px 10px;
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

/*####################*/
/* General Boxes*/
/*####################*/
/*2 cols*/
div.cols div {
  float: left;
}
div.cols div.last {
  float: right;
  margin: 0 0 0 -@gutterwidth;
}

.row-2 {
  width: @wide2;
}
.row-3 {
  width: @wide3;
}
.row-4 {
  width: @wide4;
}
.row-5 {
  width: @wide5;
}
.row-6 {
  width: @wide6;
}
.row-7 {
  width: @wide7;
}
.row-8 {
  width: @wide8;
}
.row-9 {
  width: @wide9;
}
.row-10 {
  width: @wide10;
}
.row-11 {
  width: @wide11;
}
.row-12 {
  width: @wide12;
}
.row-13 {
  width: @wide13;
}
.row-14 {
  width: @wide14;
}
/*####################*/
/* General Boxes*/
/*####################*/

div.mainwidth {
  .mainwidth;
}
header {
  position: fixed;
  background: url(../../img/headerback.png) repeat-x top left;
  height: 116px;
  width: 100%;
  z-index: 2000;
  top: 0;
}
section {
  min-height: 200px;
  position: relative;
  z-index: 20;
}

section#home {
  background: @white url(../../img/borderbottom.png) repeat-x bottom left;
  margin: @boxmargin 0 0px 0;
  padding: 0;
  color: @blue;
  z-index: 10;
}
section#home div.mainwidth {
  padding-bottom: 8px !important;
}
section#home div.fair {
  height: 376px;
  width: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  background: @fair;
}
section#home p.abstand {
  margin-top: 120px;
}
section#space {
  background: @fair;
  height: 700px;
  margin: 0;
  padding: 0;
  z-index: 1;
}
section#angebot,
section#netzwerk {
  background: @blue;
  color: @white;
  padding: @boxpaddingtop 0 @boxpaddingbottom 0;
  margin: @boxmargin 0 @boxmargin 0;
}
section#angebot {
  margin-top: 0;
  background: @blue url(../../img/border.png) repeat-x top left;
}
section#profil,
section#referenzen,
section#kontakt {
  background: @white;
  color: @blue;
  padding: @boxpaddingtop 0 @boxpaddingbottom 0;
  margin: @boxmargin 0 @boxmargin 0;
}
section#referenzen {
  padding: 0 0 @boxpaddingbottom 0;
}

section#impressum {
  background: @grey;
  color: @white;
  padding: @boxpaddingtop 0 0 0;
  margin: @boxmargin 0 0 0;
}

section#privacypolicy {
  background: @white;
  color: @blue;
  padding: @boxpaddingtop 0 0 0;
  margin: @boxmargin 0 0 0;

  p {
    padding-left: 2rem;
  }
  a {
    color: @blue;
  }
}

section#home a,
section#profil a,
section#referenzen a,
section#kontakt a {
  color: @blue;
}
section#impressum a,
section#angebot a,
section#netzwerk a {
  color: @white;
}

section#angebot p,
section#impressum p {
  margin: 0 0 1.8em 0;
}
/*####################*/
/*Nav*/
/*####################*/
nav {
}
nav ul {
  .resetList;
  background: @grey;
  .border-radius-bottom;
  width: 603px;
  height: 40px;
  padding-top: 20px;
  float: left;
}
nav ul li {
  .resetList;
  display: inline;
}
nav ul li a:link,
nav ul li a:visited {
  color: @white;
  padding: 0 0 0 25px;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 14px;
}
nav ul li a.current,
nav ul li a:hover,
nav ul li a:active {
  background: url(../../img/navact.png) no-repeat 10px 5px;
}
/*####################*/
/*Logo*/
/*####################*/
div#logo {
  float: right;
}
div#logo a.logo {
  display: block;
  height: 114px;
  width: 359px;
  background: url(../../img/logo.png) no-repeat top left;
  overflow: hidden;
  white-space: nowrap;
  text-indent: 999em;
}

/*####################*/
/*Sprite Icons*/
/*####################*/
#referenzen div.icon {
  height: 32px;
  width: 32px;
  background: url(../../img/sprite.png) no-repeat 0px 0px;
  margin: 20px 0 5px 0;
  padding: 0 !important;
}

#referenzen div.sprite-It {
  background-position: 0 0;
  width: 32px;
  height: 32px;
}
#referenzen div.sprite-automotive {
  background-position: 0 -42px;
  width: 32px;
  height: 32px;
}
#referenzen div.sprite-bauwesen {
  background-position: 0 -84px;
  width: 32px;
  height: 32px;
}
#referenzen div.sprite-chemie {
  background-position: 0 -126px;
  width: 32px;
  height: 32px;
}
#referenzen div.sprite-dienstleistung {
  background-position: 0 -168px;
  width: 32px;
  height: 32px;
}
#referenzen div.sprite-gesundheit {
  background-position: 0 -210px;
  width: 32px;
  height: 32px;
}
#referenzen div.sprite-handel {
  background-position: 0 -252px;
  width: 32px;
  height: 32px;
}
#referenzen div.sprite-kunst {
  background-position: 0 -294px;
  width: 32px;
  height: 32px;
}
#referenzen div.sprite-lebensmittel {
  background-position: 0 -336px;
  width: 32px;
  height: 32px;
}
#referenzen div.sprite-maschinenbau {
  background-position: 0 -378px;
  width: 32px;
  height: 32px;
}
#referenzen div.sprite-moebel {
  background-position: 0 -420px;
  width: 32px;
  height: 32px;
}
#referenzen div.sprite-soziales {
  background-position: 0 -462px;
  width: 32px;
  height: 32px;
}

/*####################*/
/*Form*/
/*####################*/
div.cols form div {
  float: none;
  clear: both;
}

#contact-form input[type="text"],
#contact-form input[type="email"],
#contact-form input[type="tel"],
#contact-form input[type="url"],
#contact-form textarea,
#contact-form button[type="submit"] {
  font: 400 12px/12px "Helvetica Neue", Helvetica, Arial, sans-serif;
}
#contact-form {
  text-shadow: 0 1px 0 #fff;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}
#contact-form label span {
  cursor: pointer;
  display: block;
  margin: 5px 0;
  font-weight: 900;
}
#contact-form input[type="text"],
#contact-form input[type="email"],
#contact-form input[type="tel"],
#contact-form input[type="url"],
#contact-form textarea {
  width: 80%;
  box-shadow: inset 0 1px 2px #ddd, 0 1px 0 #fff;
  -webkit-box-shadow: inset 0 1px 2px #ddd, 0 1px 0 #fff;
  -moz-box-shadow: inset 0 1px 2px #ddd, 0 1px 0 #fff;
  border: 1px solid #ccc;
  background: #fff;
  margin: 0 0 5px;
  padding: 10px;
  border-radius: 5px;
}
#contact-form input[type="text"]:hover,
#contact-form input[type="email"]:hover,
#contact-form input[type="tel"]:hover,
#contact-form input[type="url"]:hover,
#contact-form textarea:hover {
  -webkit-transition: border-color 0.3s ease-in-out;
  -moz-transition: border-color 0.3s ease-in-out;
  transition: border-color 0.3s ease-in-out;
  border: 1px solid #aaa;
}
#contact-form textarea {
  height: 100px;
  max-width: 80%;
}
#contact-form button[type="submit"] {
  cursor: pointer;
  width: 83%;
  border: none;
  background: #54758c;
  /*background-image:linear-gradient(bottom, #8C1C50 0%, #991D57 52%);
	background-image:-moz-linear-gradient(bottom, #8C1C50 0%, #991D57 52%);
	background-image:-webkit-linear-gradient(bottom, #8C1C50 0%, #991D57 52%);*/
  color: #fff;
  margin: 0 0 5px;
  padding: 10px;
  border-radius: 5px;
}
#contact-form button[type="submit"]:hover {
  /*background-image:linear-gradient(bottom, #9C215A 0%, #A82767 52%);
	background-image:-moz-linear-gradient(bottom, #9C215A 0%, #A82767 52%);
	background-image:-webkit-linear-gradient(bottom, #9C215A 0%, #A82767 52%);
	-webkit-transition:background 0.3s ease-in-out;
	-moz-transition:background 0.3s ease-in-out;
	transition:background-color 0.3s ease-in-out;*/
}
#contact-form button[type="submit"]:active {
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5);
}
#contact-form input:focus,
#contact-form textarea:focus {
  outline: 0;
  border: 1px solid #999;
}
::-webkit-input-placeholder {
  color: #888;
}
:-moz-placeholder {
  color: #888;
}
::-moz-placeholder {
  color: #888;
}
:-ms-input-placeholder {
  color: #888;
}

p.error,
p.sucsess {
  color: red;
}

#data-privacy {
  position: fixed;
  z-index: 99999;
  right: 0;
  bottom: 0;

  .data-privacy-btn {
    display: block;
    max-width: 100%;
    margin: 0;
    padding: 4px 12px;
    font-size: 14px;
    font-family: inherit;
    cursor: pointer;
    transition: 0.2s;
    border: 1px solid @blue;
    color: @white;
    background: @blue;
    text-decoration: none;
    box-shadow: 0 0 1px @white;

    &:hover,
    &:focus {
      color: @blue;
      background: @white;
      box-shadow: none;
    }
  }
}
